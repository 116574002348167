import React from 'react'
import { useTranslation } from 'react-i18next'
import * as styles from './tournament-director-attributes.module.less'
import moment from 'moment'

interface RegisteredStatusProps {
  director: {
    createdAt: string
    invitation?: null | {
      expiryDate: any | null
      createdAt: any | null
    }
    contact: null | {
      isRegistered: boolean
      firstName: string
    }
  }
}

export const RegisteredStatus: React.FC<RegisteredStatusProps> = ({ director: td }) => {
  const { t } = useTranslation()
  if (td.contact?.isRegistered) {
    return t('registered')
  } else {
    const expiry = td.invitation?.expiryDate
    const expired = expiry && moment(expiry).isBefore(moment())
    return expired ? (
      <span className={styles.inviteExpired}>{t('invitation expired')}</span>
    ) : (
      t('invited at', {
        timestamp: td.invitation?.createdAt
          ? moment(td.invitation?.createdAt)
          : moment(td.createdAt)
      })
    )
  }
}
