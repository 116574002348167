// extracted by mini-css-extract-plugin
export var bodyBase = "tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var bodyLarge = "tournament-director-attributes-module--body-large--tNnbW tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var bodyLargeBold = "tournament-director-attributes-module--body-large-bold--mJNsp tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var bodyRegular = "tournament-director-attributes-module--body-regular--nbV9n tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var bodyRegularBold = "tournament-director-attributes-module--body-regular-bold--XNJCU tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var bodySmall = "tournament-director-attributes-module--body-small--mkNE0 tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var bodySmallBold = "tournament-director-attributes-module--body-small-bold--OA0Qn tournament-director-attributes-module--body-base--IcIne tournament-director-attributes-module--site-font--QcBg2";
export var borderTop = "tournament-director-attributes-module--border-top--onftn";
export var breakWordContainer = "tournament-director-attributes-module--break-word-container--aAB4p";
export var buttonIconBase = "tournament-director-attributes-module--button-icon-base--1hd-t";
export var clickLink = "tournament-director-attributes-module--click-link--89hwu";
export var dropdownBase = "tournament-director-attributes-module--dropdown-base--PUjJH";
export var dropdownSelectBase = "tournament-director-attributes-module--dropdown-select-base--XIk5W tournament-director-attributes-module--text-input--x-Fsc";
export var flexCol = "tournament-director-attributes-module--flex-col--q6pXc";
export var formErrorMessage = "tournament-director-attributes-module--form-error-message--Kf3gq";
export var h3 = "tournament-director-attributes-module--h3--WWWUM";
export var h4 = "tournament-director-attributes-module--h4--F3gkN";
export var hoverLink = "tournament-director-attributes-module--hover-link--YKf2z";
export var hoverRow = "tournament-director-attributes-module--hover-row--nSfGN";
export var inviteExpired = "tournament-director-attributes-module--invite-expired--VS9Qe";
export var membershipContainer = "tournament-director-attributes-module--membership-container--u-m4D tournament-director-attributes-module--flex-col--q6pXc tournament-director-attributes-module--primary-border--x24t+";
export var membershipHeader = "tournament-director-attributes-module--membership-header--xjEZM";
export var membershipHeading = "tournament-director-attributes-module--membership-heading--hPvpj";
export var membershipLabel = "tournament-director-attributes-module--membership-label--+JR9r";
export var moreFiltersBorderClass = "tournament-director-attributes-module--more-filters-border-class--0tQOq";
export var pageBg = "tournament-director-attributes-module--page-bg--mnpIz";
export var pointer = "tournament-director-attributes-module--pointer--zGOLd";
export var primaryBorder = "tournament-director-attributes-module--primary-border--x24t+";
export var shadowBoxLight = "tournament-director-attributes-module--shadow-box-light--0Rel5";
export var siteFont = "tournament-director-attributes-module--site-font--QcBg2";
export var slideDownAndFade = "tournament-director-attributes-module--slideDownAndFade--MBSI5";
export var slideLeftAndFade = "tournament-director-attributes-module--slideLeftAndFade--6yKA2";
export var slideRightAndFade = "tournament-director-attributes-module--slideRightAndFade--GVJcg";
export var slideUpAndFade = "tournament-director-attributes-module--slideUpAndFade--RiL2w";
export var statusDecoration = "tournament-director-attributes-module--status-decoration--LZjMO";
export var textInput = "tournament-director-attributes-module--text-input--x-Fsc";
export var textInverted = "tournament-director-attributes-module--text-inverted--vG9mG";
export var textMediumDark = "tournament-director-attributes-module--text-medium-dark--1CISz";
export var tooltipFont = "tournament-director-attributes-module--tooltipFont--v4CRz";
export var unstyledButton = "tournament-director-attributes-module--unstyled-button--OqXzH";