import gql from 'graphql-tag'

export const GET_TOURNAMENT_DIRECTOR = gql`
  query GetTournamentDirector($id: UUID!) {
    organisationContactRelationship(id: $id) {
      id
      type
      organisationId
      createdAt
      invitation {
        url
        expiryDate
        externalId
        createdAt
      }
      contact {
        id
        isRegistered
        safePlay {
          status
        }
        firstName
        lastName
        emailAddress
        phoneNumber
      }
    }
  }
`

export const REMOVE_TOURNAMENT_DIRECTOR = gql`
  mutation RemoveTournamentDirector($id: UUID!) {
    revokeOrganisationContactRelationship(id: $id) {
      id
    }
  }
`
