import gql from 'graphql-tag'

export const INVITE_TOURNAMENT_DIRECTOR = gql`
  mutation AddTournamentDirector($contact: DirectorExternalIdInput!) {
    inviteTournamentDirectorFromExternalId(directorSpec: $contact) {
      id
      invitation {
        createdAt
      }
    }
  }
`
